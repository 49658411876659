<template>
  <item-card class="mb-4 bg-white" :has-menu="false">
    <section class="">
      <!-- time display -->
      <div>
        <span class="text-green-700 inline-block mr-2">Waktu Tes</span>
        <datetime
          id="time-start"
          title="Waktu Mulai Tes"
          placeholder="Waktu Mulai Tes"
          class="custom-datepicker inline-block"
          input-class="inline-block w-16"
          type="time"
          :use12-hour="false"
          :minute-step="5"
          v-model="form.period_schedule_started_at"
          :auto="true"
        ></datetime>
        -
        <datetime
          id="time-end"
          title="Waktu Selesai Tes"
          placeholder="Waktu Selesai Tes"
          class="custom-datepicker inline-block"
          input-class="inline-block w-16"
          type="time"
          :use12-hour="false"
          :minute-step="5"
          v-model="form.period_schedule_ended_at"
          :min-datetime="form.period_schedule_started_at"
          :auto="true"
        ></datetime>
        <button
          class="ml-2 button --primary"
          @click="triggerUpdateStudentSchedule()"
        >
          <fa-icon icon="check"></fa-icon>
        </button>
      </div>

      <span class="block">{{ member.name }}</span>
      <span class="block text-gray-600">{{ member.phone }}</span>
    </section>
  </item-card>
</template>

<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { Settings } from "luxon";
import _clone from "lodash/clone";
import { mapActions } from "vuex";

export default {
  props: {
    member: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    triggerUpdateStudentSchedule() {
      this.updateStudentSchedule({
        id: this.member.id,
        period_schedule_started_at: this.form.period_schedule_started_at,
        period_schedule_ended_at: this.form.period_schedule_ended_at,
      });
    },

    ...mapActions("student", ["updateStudentSchedule"]),
  },
  components: {
    ItemCard: () => import("@/components/Common/ItemCard.vue"),
    Datetime,
  },
  mounted() {
    this.form = _clone(this.member);
    Settings.defaultLocale = "id";
  },
  name: "MemberListItem",
};
</script>

